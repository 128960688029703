// 计算两个时间之差
export function getTimeDifference (startTime, endTime) {
  if (endTime === '') {
    return '0'
  }
  const start = (new Date(startTime)).getTime() // 传过来的开始时间转换为毫秒
  const end = (new Date(endTime)).getTime()
  if (parseInt(end) <= parseInt(start)) {
    return '0' // 结束时间小于等于开始时间
  }
  const days = parseInt(Math.abs(start - end) / 1000 / 60 / 60 / 24)
  if (days > 0) {
    return days + '天'
  }
  const hours = parseInt(Math.abs(start - end) / 1000 / 60 / 60)
  if (hours > 0) {
    return hours + '小时'
  }
  const minites = parseInt(Math.abs(start - end) / 1000 / 60)
  if (minites > 0) {
    return minites + '分钟'
  }
  const second = parseInt(Math.abs(start - end) / 1000)
  if (second > 0) {
    return second + '秒'
  }
}

/**
   * 获取一个从开始时间相加若干个月之后的月初
   * @param {String} 开始时间
   * @param {Number} 月数
   */
export function addMonth (start, months) {
  const startDate = new Date(start)
  const year = startDate.getFullYear()
  const month = startDate.getMonth()
  startDate.setFullYear(year, month + months)
  return startDate
}

// 计算档期发布天数
export function GetPublishDay (startTime, endTime) {
  const start = (new Date(startTime)).getTime() // 传过来的开始时间转换为毫秒
  const end = (new Date(endTime)).getTime()
  if (parseInt(end) < parseInt(start)) {
    return '' // 结束时间小于等于开始时间
  }
  const days = parseInt(Math.abs(start - end) / 1000 / 60 / 60 / 24)
  if (days > -1) {
    return days + 1 + '天'
  }
}

// 获取某日期N天后的日期
export function GetDateStr (startDate, AddDayCount) {
  var dd = new Date(startDate)
  dd.setDate(dd.getDate() + AddDayCount - 1)// 获取AddDayCount天后的日期
  var y = dd.getFullYear()
  var m = (dd.getMonth() + 1) < 10 ? '0' + (dd.getMonth() + 1) : (dd.getMonth() + 1)// 获取当前月份的日期，不足10补0
  var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()// 获取当前几号，不足10补0
  return y + '-' + m + '-' + d
}

// 获取当前可用的常规档期
export function GetSchedule () {
  var nowDate = new Date()
  var nowDay = nowDate.getDate()
  var nowMonth = nowDate.getMonth() + 1
  var nowYear = nowDate.getFullYear()
  var scheduleStr = [' 上', ' 下']
  var seperator1 = '-'
  var seperatorDay = 15
  var schedules = []
  var allMonth = 12
  if (nowDay < seperatorDay) { // 可以买当月下的档期
    var monthstr = ''
    if (nowMonth >= 1 && nowMonth <= 9) {
      monthstr = '0' + nowMonth
    } else {
      monthstr = nowMonth
    }
    schedules.push({ label: nowYear + seperator1 + monthstr + ' 下', value: nowYear + seperator1 + monthstr + seperator1 + '15' })
    allMonth -= 1
  }
  for (var i = 1; i <= allMonth; i++) {
    var year = nowYear
    var month = nowMonth + i
    if (month > 12) {
      const x = parseInt(month / 12)
      if (month % 12 === 0) {
        month = 12
        year = year + x - 1
      } else {
        month = month - 12 * x
        year = year + x
      }
    }
    if (month >= 1 && month <= 9) {
      month = '0' + month
    }
    for (var j = 0; j < scheduleStr.length; j++) {
      var strDate = scheduleStr[j] === ' 上' ? '01' : '15'
      schedules.push({ label: year + seperator1 + month + scheduleStr[j], value: year + seperator1 + month + seperator1 + strDate })
    }
  }
  if (nowDay < seperatorDay) {
    var yearstr = nowYear + parseInt((allMonth + 1) / 12)
    if (nowMonth >= 1 && nowMonth <= 9) {
      monthstr = '0' + nowMonth
    }

    schedules.push({ label: yearstr + seperator1 + monthstr + ' 上', value: yearstr + seperator1 + monthstr + seperator1 + '01' })
    allMonth -= 1
  }
  return schedules
}

// 前移几个月
export function GetPrevSchedule (prevMonth) {
  var nowDate = new Date(getMonth(new Date(), -prevMonth))

  var nowDay = nowDate.getDate()
  var nowMonth = nowDate.getMonth() + 1
  var nowYear = nowDate.getFullYear()
  var scheduleStr = [' 上', ' 下']
  var seperator1 = '-'
  var seperatorDay = 15
  var schedules = []
  var allMonth = 13 + prevMonth
  if (nowDay < seperatorDay) { // 可以买当月下的档期
    var monthstr = ''
    if (nowMonth >= 1 && nowMonth <= 9) {
      monthstr = '0' + nowMonth
    } else {
      monthstr = nowMonth
    }
    schedules.push({ label: nowYear + seperator1 + monthstr + ' 下', value: nowYear + seperator1 + monthstr + seperator1 + '15' })
    allMonth -= 1
  }
  for (var i = 1; i <= allMonth; i++) {
    var year = nowYear
    var month = nowMonth + i
    if (month > 12) {
      const x = parseInt(month / 12)
      if (month % 12 === 0) {
        month = 12
        year = year + x - 1
      } else {
        month = month - 12 * x
        year = year + x
      }
    }
    if (month >= 1 && month <= 9) {
      month = '0' + month
    }
    for (var j = 0; j < scheduleStr.length; j++) {
      var strDate = scheduleStr[j] === ' 上' ? '01' : '15'
      schedules.push({ label: year + seperator1 + month + scheduleStr[j], value: year + seperator1 + month + seperator1 + strDate })
    }
  }

  if (nowDay < seperatorDay) {
    // 移除最后一位
    schedules.splice(schedules.length - 1, 1)
  }
  return schedules
}

// 获取当年
export function GetCurrentYear () {
  var nowDate = new Date()
  var nowYear = nowDate.getFullYear()
  var schedules = {}

  schedules = { startDate: nowYear + '-01-01', endDate: (nowYear + 1) + '-01-01' }
  return schedules
}

// 获取当季
export function GetCurrentSeason () {
  var nowDate = new Date()
  var nowMonth = nowDate.getMonth() + 1
  var nowYear = nowDate.getFullYear()
  var schedules = {}
  if (nowMonth >= 1 && nowMonth <= 3) {
    schedules = { startDate: nowYear + '-01-01', endDate: nowYear + '-04-01' }
  } else if (nowMonth >= 4 && nowMonth <= 6) {
    schedules = { startDate: nowYear + '-04-01', endDate: nowYear + '-07-01' }
  } else if (nowMonth >= 7 && nowMonth <= 9) {
    schedules = { startDate: nowYear + '-07-01', endDate: nowYear + '-10-01' }
  } else {
    schedules = { startDate: nowYear + '-10-01', endDate: (nowYear + 1) + '-01-01' }
  }
  return schedules
}

// 获取当月
export function GetCurrentMonth () {
  var nowDate = new Date()
  var nowMonth = nowDate.getMonth() + 1
  var nowYear = nowDate.getFullYear()
  var schedules = {}

  // if (nowMonth === 12) {
  //   schedules = {startDate: nowYear + '-12-01', endDate: (nowYear + 1) + '-01-01'}
  // } else {
  //   schedules = {startDate: nowYear + '-' + (nowMonth + '').padStart(2, '0') + '-01', endDate: nowYear + '-' + ((nowMonth + 1) + '').padStart(2, '0') + '-01'}
  // }
  schedules = { startDate: nowYear + '-' + (nowMonth + '').padStart(2, '0') + '-01', endDate: lastMonthDate(nowYear, nowMonth) }

  return schedules
}

// 获取当前档期
export function GetCurrentSchedule (startDate, endDate, showDays = true) {
  if (startDate === '' || endDate === '') {
    return '-'
  }

  // 临时屏蔽注释掉月上月下的处理， 后续看客户成功部的反馈

  // var start = new Date(startDate)
  // var end = new Date(endDate)
  // var startDay = start.getDate()
  // var endDay = end.getDate()

  // var startMonth = start.getMonth() + 1
  // var monthstr = startMonth
  // var endMonth = end.getMonth() + 1
  // if (startMonth >= 1 && startMonth <= 9) {
  //   monthstr = '0' + startMonth
  // }
  // var nowYear = start.getFullYear()
  // var returnStr = ''
  var returnStr = startDate + '至' + endDate
  // if (startMonth !== endMonth) {
  //   returnStr = startDate + '至' + endDate
  // } else if (startDay === 1 && endDay === 14) {
  //   returnStr = nowYear + '-' + monthstr + ' 上'
  // } else if (startDay === 15 && endDay === 28) {
  //   returnStr = nowYear + '-' + monthstr + ' 下'
  // } else {
  //   returnStr = startDate + '至' + endDate
  // }
  if (showDays) {
    returnStr = returnStr + '(' + GetPublishDay(startDate, endDate) + ')'
  }
  return returnStr
}

// 获取下个档期
export function GetNextSchedule () {
  var nowDate = new Date()

  var nowDay = nowDate.getDate()
  var nowMonth = nowDate.getMonth() + 1
  var nowYear = nowDate.getFullYear()
  var schedules = {}
  var monthstr = ''

  if (nowDay < 15) { // 可以买当月下的档期
    if (nowMonth >= 1 && nowMonth <= 9) {
      monthstr = '0' + nowMonth
    } else {
      monthstr = nowMonth
    }
    schedules = { startDate: nowYear + '-' + monthstr + '-15', endDate: nowYear + '-' + monthstr + '-29' }
  } else {
    if (nowMonth === 12) {
      nowYear += 1
      monthstr = '01'
    } else {
      nowMonth += 1
      if (nowMonth >= 1 && nowMonth <= 9) {
        monthstr = '0' + nowMonth
      } else {
        monthstr = nowMonth
      }
    }
    schedules = { startDate: nowYear + '-' + monthstr + '-01', endDate: nowYear + '-' + monthstr + '-15' }
  }
  return schedules
}

/**
   * 格式化日期，
   * @param {*} date 要转换的日期，如果不是 Date 类型，则会抛出一个日期格式不正确的错误
   * @param {String} spliter 分隔符，默认是 ‘-’
   */
export function ParseDate (date, spliter = '-') {
  const dateObj = new Date(date)
  if (dateObj instanceof Date) {
    return dateObj.getFullYear() + spliter +
        ((dateObj.getMonth() + 1) + '').padStart(2, '0') + spliter +
        (dateObj.getDate() + '').padStart(2, '0')
  } else {
    throw new Error('日期格式不正确')
  }
}

/**
   * 格式化日期，
   * @param {*} date 要转换的日期，如果不是 Date 类型，则会抛出一个日期格式不正确的错误
   * @param {String} spliter 分隔符，默认是 ‘-’
   */
export function ParseDateForMonth (date, spliter = '-') {
  const dateObj = new Date(date)
  if (dateObj instanceof Date) {
    return dateObj.getFullYear() + spliter +
          ((dateObj.getMonth() + 1) + '').padStart(2, '0')
  } else {
    throw new Error('日期格式不正确')
  }
}

/**
   * 格式化日期，
   * @param {*} date 要转换的日期，如果不是 Date 类型，则会抛出一个日期格式不正确的错误
   * @param {String} spliter 分隔符，默认是 ‘-’
   */
export function ParseDateTime (date, spliter = '-') {
  const dateObj = new Date(date)
  if (dateObj instanceof Date) {
    return dateObj.getFullYear() + spliter +
        ((dateObj.getMonth() + 1) + '').padStart(2, '0') + spliter +
        (dateObj.getDate() + '').padStart(2, '0') + ' ' +
        (dateObj.getHours() + '').padStart(2, '0') + ':' +
        (dateObj.getMinutes() + '').padStart(2, '0') + ':' +
        (dateObj.getSeconds() + '').padStart(2, '0')
  } else {
    throw new Error('日期格式不正确')
  }
}

// 获取下月
export function GetNextMonth (data) {
  var nowDate = new Date(data)
  var nowMonth = nowDate.getMonth() + 1
  var nowYear = nowDate.getFullYear()

  if (nowMonth === 12) {
    return (nowYear + 1) + '-01-01'
  } else {
    return nowYear + '-' + ((nowMonth + 1) + '').padStart(2, '0') + '-01'
  }
}
// 获取上月
export function GetPrevMonth (data) {
  var nowDate = new Date(data)
  var nowMonth = nowDate.getMonth() + 1
  var nowYear = nowDate.getFullYear()

  if (nowMonth === 1) {
    return (nowYear - 1) + '-12-01'
  } else {
    return nowYear + '-' + ((nowMonth - 1) + '').padStart(2, '0') + '-01'
  }
}

/**
   * 格式化日期，
   * @param {*} date 要转换的日期，如果不是 Date 类型，则会抛出一个日期格式不正确的错误
   * @param {Number} addMonths 分隔符，默认是 ‘-’
   */
export function getMonth (date, addMonths = 0) {
  const dateObj = new Date(date)
  dateObj.setMonth(dateObj.getMonth() + addMonths)
  if (dateObj instanceof Date) {
    return dateObj.getFullYear() + '-' +
        ((dateObj.getMonth() + 1) + '').padStart(2, '0')
  } else {
    throw new Error('日期格式不正确')
  }
}

// 获取每月最后一天
export function lastMonthDate (year, month) {
  const day = new Date(year, month, 0)
  return year + '-' + (month + '').padStart(2, '0') + '-' + day.getDate()
}

export function DateDiff (sDate1, sDate2) { // sDate1和sDate2是xxxx-xx-xx格式
  var aDate, oDate1, oDate2, iDays
  aDate = sDate1.split('-')
  oDate1 = new Date(aDate[1] + '/' + aDate[2] + '/' + aDate[0])// 转换为xx-xx-xxxx格式
  aDate = sDate2.split('-')
  oDate2 = new Date(aDate[1] + '/' + aDate[2] + '/' + aDate[0])
  iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24) // 把相差的毫秒数转换为天数
  return iDays + 1
}
// 获取当前时间的本周的第一天的日期
// 注意：参数fmt 表示返回格式：例如YYYY-mm-dd HH:MM:SS或者YYYY-mm-dd，等
// 参数date 表示传输时间，如果这个参数为空，那么会默认当前时间
export function getWeekFirstDay (datetime) {
  var Nowdate = new Date()
  if (datetime) {
    Nowdate = new Date(datetime)
  }

  var nowTime = Nowdate.getTime()
  var day = Nowdate.getDay() || 7
  var oneDayTime = 24 * 60 * 60 * 1000
  var Monday = nowTime - (day - 1) * oneDayTime
  var mondayTime = new Date(Monday)
  var year = mondayTime.getFullYear()
  var monMath = mondayTime.getMonth() + 1
  var month = (monMath < 10) ? '0' + '' + monMath + '' : monMath
  var data = (mondayTime.getDate() < 10) ? '0' + '' + mondayTime.getDate() + '' : mondayTime.getDate()
  var mondayDate = '' + year + '-' + month + '-' + data
  return mondayDate
}

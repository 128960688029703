import request from '@/utils/requestV2'
const qs = require('qs')
// 获取任务数
export function gettaskcount (data) {
  return request({
    url: '/ooh-scp/v1/financetask/gettaskcount',
    method: 'post',
    data: qs.stringify(data)
  })
}

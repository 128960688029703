<template>
    <div>
        <Row class="task-amount">
            <i-col span="12" style="cursor:pointer;" :class="showAllTask?'text-orange':''" class="task-amount-border" >
                <p class="task-amount-title" @click="changeShowAllTask(true)">总计</p>
                <p class="task-amount-subtitle" @click="changeShowAllTask(true)">{{taskCount.totalTaskCount}}</p>
            </i-col>
            <i-col span="12" style="cursor:pointer;" :class="!showAllTask?'text-orange':''" >
                <p class="task-amount-title" @click="changeShowAllTask(false)">待办</p>
                <p class="task-amount-subtitle" @click="changeShowAllTask(false)">{{taskCount.totalPendingTaskCount}}</p>
            </i-col>
        </Row>

        <Row class="p-t-20" :gutter="8">
             <i-col :xs="24" :sm="24" :md="24"  :lg="16" class="m-b-5">
                <div v-for="type in taskTypes" :key="type.id" class="p-l-10 p-r-10 m-b-2" @click="changeTaskType(type.id)"
                    :class="taskType===type.id?'workplatform-radio-button-active':'workplatform-radio-button'">{{type.name}}</div>
            </i-col>
            <i-col :xs="24" :sm="12" :md="12"  :lg="4" class="m-b-5">
                <DatePicker transfer v-model="launchDate" size="small" format="yyyy-MM-dd" type="date" placeholder="发起日期" ></DatePicker>
            </i-col>
             <i-col :xs="24" :sm="12" :md="12"  :lg="4" class="m-b-5">
                <i-input  v-model="keyword" size="small"  placeholder="关键字：名称、发起人等"></i-input>
            </i-col>
        </Row>

        <component :is="currentComponent + 'Component'" @on-showDetail="handleEdit" :keyword.sync="keyword" :applyDate.sync="filterlaunchDate" :showAllTask.sync="showAllTask" />

    </div>
</template>

<script>
import { gettaskcount } from '@/api/scp/financetask'
import { ParseDate } from '@/utils/dateFormat'
export default {
  props: {
    defaultshowAllTask: Boolean
  },
  components: {
    invoiceComponent: (resolve) => {
      require(['./invoice/List'], resolve)
    }
  },
  data () {
    return {
      showAllTask: this.defaultshowAllTask,
      taskTypes: [],
      taskType: 1,
      currentComponent: 'invoice',
      taskCount: {},
      launchDate: '',
      filterlaunchDate: '',
      keyword: ''
    }
  },
  created () {
    this.initData()
  },
  methods: {
    toparseDate (date) {
      return ParseDate(date)
    },
    // 初始化任务类型数量
    initData () {
      gettaskcount().then(res => {
        if (res && !res.errcode) {
          this.taskCount = res
          this.taskTypes = res.taskItemCountList.map(item => {
            return { id: item.financeTaskType, name: item.financeTaskTypeName }
          })
          if (this.taskTypes.length) {
            this.taskType = this.taskTypes[0].id
            this.initListComponent()
          }
        }
      })
    },
    // 根据当前的任务类型加载不同的列表页面
    initListComponent () {
      if (this.taskType === 1) {
        this.currentComponent = 'invoice'
      }
    },
    // 切换是否展示全部任务
    changeShowAllTask (val) {
      if (this.showAllTask !== val) {
        this.showAllTask = val
        this.$emit('on-changeShowAllTask', val)
      }
    },
    // 处理任务（到任务详情页面）
    handleEdit (id) {
      this.$emit('on-showDetail', id, this.taskType)
    },
    // 变更任务类型
    changeTaskType (id) {
      this.taskType = id
    }
  },
  watch: {
    launchDate (val) {
      this.filterlaunchDate = val ? this.toparseDate(val) : ''
    }
  }
}
</script>
